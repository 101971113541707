import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import Globals from '@/globals';
import StudioPageBase from '@/support/studioPageBase';
import { StudioModule } from '@/store/studio';
import Util from '@/support/utility';
import { IWizardManager } from '@/interfaces';
import { ToggleButton } from 'vue-js-toggle-button';
import PlayerUtil from '@/support/playerUtil';

Vue.component('ToggleButton', ToggleButton);

@Component
export default class EditStudioComponent extends StudioPageBase {
    errors: string[] = [];

    model = Globals.NewStudio;
    originals = Globals.NewStudio;
    category = Globals.NewCategory;

    loading = true;
    loaded = false;

    saveDisabled: boolean = true;

    options = {
    };

    @Prop({ default: null })
    wizard: IWizardManager;

    @Prop({ default: <any>{} })
    parent: any;

    get record() {
        return !this.model.liveOnly;
    }

    set record(value) {
        this.model.liveOnly = !value;
    }

    get secure() {
        return !this.model.noSecurity;
    }

    set secure(value) {
        this.model.noSecurity = !value;
    }

    @Watch('model.studio', { immediate: true })
    onStudioChanged(val: string, oldVal: string) {
        this.updateButtons();
    }

    @Watch('model.title', { immediate: true })
    onTitleChanged(val: string, oldVal: string) {
        this.updateButtons();
    }

    @Watch('category', { immediate: true })
    onCategoryChanged(val: string, oldVal: string) {
        this.updateButtons();
    }

    @Watch('secure', { immediate: true })
    onSecureChanged(val: string, oldVal: string) {
        this.updateButtons();
    }

    @Watch('record', { immediate: true })
    onRecordChanged(val: string, oldVal: string) {
        this.updateButtons();
    }

    created() {
        Debug.setDebugModule('EditStudio', this);

        super.created('editStudio', true);
    }

    mounted() {
        super.mounted();

        this.parent = this.wizard.parent;
    }

    protected onLoaded(loaded: boolean) {
        if (!loaded) {
            PlayerUtil.redirectToError(this.$router);
            return;
        }

        this.model = Object.assign<SUR.AzureStudioDto>({}, this.studio);
        this.originals = Object.assign<SUR.AzureStudioDto>({}, this.studio);
        this.category = this.getStudioCategory();
        this.loaded = true;
        this.loading = false;
    }

    updateButtons() {
        if (!this.model.studio || !this.model.title ||
            (this.model.studio.trim() == this.originals.studio &&
                this.model.title.trim() == this.originals.title &&
                this.category.name == this.originals.category &&
                this.model.liveOnly != this.originals.liveOnly &&
                this.model.noSecurity != this.originals.noSecurity))
            this.saveDisabled = true;
        else
            this.saveDisabled = false;
    }

    validateInput() {
        this.errors.splice(0, this.errors.length);

        if (!this.model.title)
            this.errors.push('Friendly name required');
        else if (this.model.studio != Util.sanitizeSentence(this.model.studio))
            this.errors.push('Invalid Friendly name - no funny characters.');

        if (!this.model.studio)
            this.errors.push('Alias required');
        else if (this.model.studio != Util.sanitizeId(this.model.studio))
            this.errors.push('Invalid Alias: letters and number only.');

        if (!this.category)
            this.errors.push('Category required');
        else if (!this.category.id || !this.category.name)
            this.errors.push('Invalid category');

        return !this.errors.length;
    }

    async done() {
        if (!this.wizard) return false;
        if (!this.validateInput()) return false;

        let categoryName = this.category ? this.category.name : null;
        let category = this.categories.find(item => item.name == categoryName);
        if (!category) {
            Debug.error('Missing category', categoryName, this.categories);
            Util.showError('Missing category');
            return;
        }

        try {
            this.model.category = category.name;
            this.model.categoryId = category.id;
            this.model.categoryOrder = category.order;

            Debug.log('editStudio', this.model.studio, this.model.title, this.model.category);

            await StudioModule.editStudio(this.model);

            Debug.log('editStudio done SUCCEEDED');
            Util.showToast('Saved portal details ' + this.model.studio);

            if (!this.wizard) return null;
            this.wizard.back();

            return null;
        } catch (err) {
            let message = err.message || 'ERROR';
            Debug.error("editStudio FAILED", this.model.studio, message);
            Util.showToast('Failed to save portal details ' + this.model.studio, true);
            return null;
        } finally {

        }
    }

    cancel() {
        if (!this.wizard) return;
        this.wizard.back();
    }

    manageCategories() {
        if (!this.wizard) return;
        this.wizard.navigateTo('categories');
    }

}
